import React from "react"
import { css } from "styled-components"

import Layout from "../components/layout"
import Hero from "../components/hero"
import SEO from "../components/seo"

const IndexPage = () => (
  <Layout>
    <SEO title="Home" />
    <div
      css={css`
        overflow: hidden;

        @media (min-width: 480px) {
          padding-top: 50px;
        }
      `}
    >
      <Hero />
    </div>
  </Layout>
)

export default IndexPage
