import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import Img from "gatsby-image"
import { css } from "styled-components"

const wrapperStyles = css`
  margin: 0 auto;
  text-align: center;

  & > div {
    display: inline-block;
    width: 300px;
    height: 300px;
    border-radius: 50%;
    overflow: hidden;
  }
`

const Hero = () => {
  const data = useStaticQuery(graphql`
    query {
      ainsley: file(relativePath: { eq: "ainsley.png" }) {
        childImageSharp {
          fixed(width: 300, height: 300, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
      elsa: file(relativePath: { eq: "elsa.png" }) {
        childImageSharp {
          fixed(width: 300, height: 300, quality: 100) {
            ...GatsbyImageSharpFixed
          }
        }
      }
    }
  `)

  return (
    <div css={wrapperStyles}>
      <div>
        <Img fixed={data.elsa.childImageSharp.fixed} alt="Hero image" />
      </div>
      <div>
        <Img fixed={data.ainsley.childImageSharp.fixed} alt="Hero image" />
      </div>
    </div>
  )
}

export default Hero
